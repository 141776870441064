<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <div class="card-detail-left__date">
<!--            {{ new Date() | moment("DD MMM, YYYY") }}-->
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <router-link :to="$store.getters.GET_PATHS.fedexUkraineReport" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('iossNumbers_BackToList.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'menu_FedexUkraineReport',
                  ])"></div>
              {{$t('menu_FedexUkraineReport.localization_value.value')}}
            </div>
            <div class="mb-2">
              <MainButton
                  class="order-create__footer-btn-i secondary wfc"
                  :value="$t('common_generateManifest.localization_value.value')"
                  :class="{'disabled-btn' : isHasChecked}"
                  @click.native="$emit('exportFedexUkraineReport')"
              />
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <StepA
                :FUR="FUR"
                @reload="$emit('reload')"
            />

          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_cancel',
                  ])"></div>
              <router-link :to="$store.getters.GET_PATHS.fedexUkraineReport"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_export',
                  ])"></div>


<!--              <MainButton-->
<!--                  class="order-create__footer-btn-i"-->
<!--                  :value="$t('common_save.localization_value.value')"-->
<!--                  @click.native="$emit('saveFedexUkraineReport', true)"-->
<!--              />-->
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import StepA from "../../chunks/StepA";

  export default {
    name: "FedexUkraineReportEditAdmin",
    components: {
      StepA,
      MainButton,
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
    },

    mixins: [mixinDetictingMobile],

    props: {
      FUR: Object,
    },

    computed: {
      isHasChecked() {
        console.log(12222);
        console.log(this._.find(this.FUR.data.relatedOrders, {is_checked: true})?.id);
        return this._.find(this.FUR.data.relatedOrders, {is_checked: true})?.id ? false : true
      }
    },

    data(){
      return{

      }
    },
  }
</script>

<style scoped>
  .checkbox-scoped{
    margin-right: -5px;
  }

  td:nth-child(2){
    font-size: 12px;
    line-height: 14px;
  }
</style>
