import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function FedexUkraineReport() {

  this.checkValid = checkValid

  this.data = {
    order: null,
    date: '',
    relatedOrders: [],
  }


  this.validation = {
    date: false,
  }

  this.validationTranslate = {
    date: '',
  }

  this.validationTxt = {
    date: false,
  }

  /**
   * Getters
   */
  this.getDate = () => {
    return this.data.date
  }


  /**
   * Setters
   */
  this.setDate = (val) => {
    this.data.date = val
  }

}

/**
 * Global Setters
 */

FedexUkraineReport.prototype.setItem = function(val) {
  this.data.order = val
  this.data.relatedOrders = val.elements
}


/**
 * Prepare Data
 */

FedexUkraineReport.prototype.prepareSave = function(that) {
  return that.$moment(this.getDate()).format("YYYY-MM-DD")
}


/**
 * Validations
 */

FedexUkraineReport.prototype.firstValidation = function() {
  let validationItems = {
    keyword: this.getDate(),
  }

  let validationOptions = {
    keyword: {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}
