<template>
  <FedexUkraineReportEditAdmin
      v-if="loaded"
      :FUR="FUR"
      @reload="reload"
      @exportFedexUkraineReport="exportFedexUkraineReport"
  />
</template>

<script>
  import {FedexUkraineReport} from "../../models/FedexUkraineReport";
  import FedexUkraineReportEditAdmin from "./FedexUkraineReportEditAdmin/FedexUkraineReportEditAdmin";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "FedexUkraineReportEdit",

    components: {
      FedexUkraineReportEditAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        loaded: false,
        FUR: new FedexUkraineReport(),
      }
    },

    created() {
      this.initFedexUkraineReportEdit()
    },

    methods: {

      reload() {
        this.FUR = new FedexUkraineReport()
        this.initFedexUkraineReportEdit()
      },

      initFedexUkraineReportEdit() {

        this.$store.dispatch('getFedexUkraineReport', this.$route.params.id).then((response) => {
          this.FUR.setItem(this.getRespData(response))
          this.loaded = true
        }).catch(error => this.createErrorLog(error))

      },

      exportFedexUkraineReport() {
        this.$store.dispatch('exportFedexUkraineReportElement', this.$route.params.id).then(response => {
          if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.createDownloadLink({data: response.data, name: `FedexUkraineReport.${'xlsx'}`, type: 'xlsx'})
          }
          else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })
      },

    }
  }
</script>

<style scoped>

</style>