<template>
  <div class="fragment" v-bind:class="{'disabled' : $store.getters.getFedexUkraineReportBtn}">
    <div class="order-create__row custom-row">
      <div v-if="FUR.data.relatedOrders.length > 0" class="custom-col">
        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
              'common_id',
              'common_date',
              'common_user',
              ])"></div>
          <table class="site-table">
            <thead>
            <tr>
              <th>
                <DefaultCheckbox
                    class="empty-label white brown-border"
                    :value="allChecked"
                    @input="changeAllChecked"
                />
              </th>
              <th class="white-space-line">{{$t('common_id.localization_value.value')}}</th>
              <th class="white-space-line">{{$t('common_date.localization_value.value')}}</th>
              <th class="white-space-line">{{$t('common_user.localization_value.value')}}</th>
              <th class="white-space-line">{{$t('returnGoods_trackingNumber.localization_value.value')}}</th>
<!--              <th width="20%" class="white-space-line">{{$t('common_country.localization_value.value')}}</th>-->
<!--              <th width="20%" class="white-space-line">{{$t('common_city.localization_value.value')}}</th>-->
              <th align="right" class="pr-0"></th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in FUR.data.relatedOrders"
                :key="index"
            >
              <td class="pr-0">
                <DefaultCheckbox
                    class="checkbox-scoped"
                    :value="item.is_checked"
                    @input="item.is_checked = !item.is_checked; checkElements(false, item.id)"
                />
              </td>
              <td>
                <div class="table-row">
                  #{{item.express_id}}
                </div>
              </td>
              <td>
                {{ item.courier_date | moment("DD MMM, YYYY") }}
              </td>
              <td>
                <TableUserColumn
                    v-if="isAdmin"
                    :item="item"
                />
              </td>
              <td>
                <div class="table-row">
                  <ValueHelper
                      :value="item"
                      :deep="'tracking_number'"
                  />
                </div>
              </td>
              <td align="right" class="pr-0">
                <div class="table-right table-manage-list justify-content-end">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="w160 right ">
                    <template slot="item">
                      <LinkButton
                          :value="$t('search_show.localization_value.value')"
                          :target="'_blank'"
                          :type="'show'"
                          :link="`${$store.getters.GET_PATHS.expressOrderShow}/${getExpressTypeById(item.delivery_service_id).name}/${item.express_id}`"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeRelatedOrder(item)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in FUR.data.relatedOrders"
                 :key="index"
            >
              <div class="site-table-mobile__item">
                <div class="site-table-mobile__head">
                  <div class="site-table-mobile__head-checkbox">
                    <DefaultCheckbox
                        class="checkbox-scoped"
                        :value="item.is_checked"
                        @input="item.is_checked = !item.is_checked"
                    />
                  </div>
                  #{{item.express_id}}
                </div>
                <div class="site-table-mobile__row custom-row">

                  <div class="site-table-mobile__piece custom-col custom-col--50">
                    {{$t('common_date.localization_value.value')}}
                    <span>
                      {{ item.courier_date | moment("DD MMM, YYYY") }}
                    </span>
                  </div>

                  <div class="site-table-mobile__piece custom-col custom-col--50">
                    {{$t('common_user.localization_value.value')}}
                    <span>
                      <TableUserColumn
                          v-if="isAdmin"
                          :item="item"
                      />
                    </span>
                  </div>

                  <div class="site-table-mobile__piece custom-col custom-col--50">
                    {{$t('returnGoods_trackingNumber.localization_value.value')}}
                    <span>
                      <ValueHelper
                        :value="item"
                        :deep="'tracking_number'"
                    />
                    </span>
                  </div>

                  <div class="site-table-mobile__piece custom-col">
                    <div class="table-right table-manage-list">
                      <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="mw-100">
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_show.localization_value.value')"
                              :target="'_blank'"
                              :type="'show'"
                              :link="`${$store.getters.GET_PATHS.expressOrderShow}/${getExpressTypeById(item.delivery_service_id).name}/${item.express_id}`"
                          />
                        </template>
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeRelatedOrder(item)"
                          />
                        </template>
                      </ManagerButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import ManagerButton from "../../../../UI/buttons/ManagerButton/ManagerButton";
import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import {expressMixins} from "@/mixins/expressMixins/expressMixin";

export default {
  name: "ScannerBlock",
  components: {
    TableUserColumn,
    ValueHelper,
    LinkButton,
    ManagerButton,
    DefaultCheckbox,
  },

  mixins: [mixinDetictingMobile, expressMixins],

  props: {
    FUR: Object,
    created: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      allChecked: false,
    }
  },

  mounted() {

  },

  methods: {
    changeAllChecked() {
      if(!this.allChecked) {
        this.FUR.data.relatedOrders.forEach(order => {
          order.is_checked = true
        })
        this.allChecked = true
      } else {
        this.FUR.data.relatedOrders.forEach(order => {
          order.is_checked = false
        })
        this.allChecked = false
      }

      this.checkElements(true)
    },

    checkElements(all, id) {

      let elements = []

      this.FUR.data.relatedOrders.forEach(order => {
        if(all || (id && id === order.id)) {
          elements.push({
            id: order.id,
            value: order.is_checked,
          })
        }
      })

      this.$store.dispatch('checkElementsFedexUkraineReport', {id: this.$route.params.id, data: {elements}}).then(response => {
        console.log(response);
      })


    },

    removeRelatedOrder(item) {
      this.$store.dispatch('deleteFedexUkraineReportElement', item.id).then(response => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordDeleted')
            this.$emit('reload')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },
  }
}
</script>

<style scoped>

</style>